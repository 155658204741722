import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useGetContactCollectionByMagicLinkQuery, useCreateContactMutation, useGetTelemetryDataForContactCollectionGuestQuery, useDeleteContactMutation } from '@graphql/generated';
import { VisualType } from '../routes/Create/components/Editor/components/Switch/Switch';
import { getImageSource } from '../routes/Create/components/Preview/Preview';
import { useTranslation } from '@shared/core';
import { useContactCollectorGuestTelemetryTelemetry } from './ContactCollectorGuest.telemetry';
import { NATIONAL_COUNTRY, NATIONAL_COUNTRY_CODE } from '@apps/admin/common/ShippingAddress/constants';

export interface ContactInputFields
  extends Readonly<{
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    email: string;
    address1: string;
    address2?: string;
    postalCode: string;
    city: string;
    region: string;
    country: string;
    countryCode: string;
    validated: boolean;
  }> {}

const initialValues: ContactInputFields = {
  id: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  address1: '',
  address2: '',
  postalCode: '',
  city: '',
  region: '',
  country: NATIONAL_COUNTRY,
  countryCode: NATIONAL_COUNTRY_CODE,
  validated: false
};

export interface Context
  extends Readonly<{
    coupleNames: string;
    eventDisplayName: string;
    date: string;
    eventDateDisplayEnabled: boolean;
    website: string;
    websiteLinkEnabled: boolean;
    fontFamily: string;
    fontTextTransform: string;
    mediaType: string;
    mediaSource: string;
    firebaseId: string;
  }> {}

export const useContactCollectorGuestController = (magicLinkId: string) => {
  const [context, setContext] = useState<Context>({
    coupleNames: '',
    eventDisplayName: '',
    date: '',
    eventDateDisplayEnabled: false,
    website: '',
    websiteLinkEnabled: false,
    fontFamily: 'PT Serif',
    fontTextTransform: 'none',
    mediaType: '',
    mediaSource: '',
    firebaseId: ''
  });
  const { trackError } = useContactCollectorGuestTelemetryTelemetry();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');
  const { t2 } = useTranslation('ssrServer');
  const tMetatags = t2('metatags');
  const { value: contactCollectionFeatureEnabled, loading: isFeatureFlagLoading } = useFeatureValue('contactCollectionEnabled');
  const { data, error } = useGetContactCollectionByMagicLinkQuery({
    variables: {
      magicLink: magicLinkId
    },
    batchMode: 'fast',
    ssr: true,
    fetchPolicy: 'cache-first',
    onError: error => trackError('Query', error)
  });

  const eventId = data?.getContactCollectionByMagicLink?.eventId;
  const isContactCollectionInactive = !!!data?.getContactCollectionByMagicLink?.active;

  const { data: telemetryQueryData, loading: isTelemetryQueryLoading } = useGetTelemetryDataForContactCollectionGuestQuery({
    batchMode: 'fast',
    ssr: false,
    variables: {
      eventId: eventId!
    },
    skip: !eventId,
    onError: error => trackError('Query', error)
  });

  const ogImage = getImageSource(data?.getContactCollectionByMagicLink?.mediaType as VisualType, data?.getContactCollectionByMagicLink?.mediaSource ?? '');
  const ogTitle = data?.getContactCollectionByMagicLink?.message || tMetatags.title;
  const ogUrl = `https://withjoy.com/contact/${magicLinkId}`;

  const [createContactMutation, { loading: isCreateContactMutationLoading, error: createContactMutationError }] = useCreateContactMutation();
  const [deleteContactMutation, { loading: isDeleteContactMutationLoading, error: deleteContactMutationError }] = useDeleteContactMutation();

  const formik = useFormik<ContactInputFields>({
    initialValues,
    validationSchema: Yup.object<ContactInputFields>({
      id: Yup.string(),
      firstName: Yup.string().required(tAdmin.required()),
      lastName: Yup.string().required(tAdmin.required()),
      phoneNumber: Yup.string(),
      email: Yup.string().email(tAdmin.invalidEmail()).required(tAdmin.required()),
      address1: Yup.string().required(tAdmin.required()),
      address2: Yup.string(),
      postalCode: Yup.string().required(tAdmin.required()),
      city: Yup.string().required(tAdmin.required()),
      region: Yup.string().required(tAdmin.required()),
      country: Yup.string(),
      countryCode: Yup.string(),
      validated: Yup.boolean()
    }),
    validateOnMount: true,
    onSubmit: async (values, formikHelpers) => {
      try {
        const contactCollectionId = data?.getContactCollectionByMagicLink?.id;

        if (!contactCollectionId) {
          throw Error('No Collection Id provided');
        }

        const mutationData = {
          address: {
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            country: values.country,
            countryCode: values.countryCode,
            postalCode: values.postalCode,
            region: values.region,
            validated: values.validated
          },
          contact: {
            contactCollectionId,
            firebaseEventId: context.firebaseId,
            email: (values.email ?? '').trim(),
            firstName: (values.firstName ?? '').trim(),
            lastName: (values.lastName ?? '').trim()
          },
          phoneNumbers: values.phoneNumber
            ? [
                {
                  allowSMS: true,
                  mobile: true,
                  number: values.phoneNumber.trim()
                }
              ]
            : undefined
        };

        if (values.id) {
          await deleteContactMutation({ variables: { id: values.id, firebaseEventId: context.firebaseId } });
        }

        const { data: createContactMutationData } = await createContactMutation({ variables: { data: mutationData } });

        formikHelpers.setFieldValue('id', createContactMutationData?.createContact.id);
      } catch (error) {
        trackError('Mutation', error);
      }
    }
  });

  return {
    tAdmin,
    showErrorPage: !contactCollectionFeatureEnabled || !!error,
    isContactCollectionInactive,
    eventHandle: telemetryQueryData?.eventById?.website ?? '',
    isLoading: isFeatureFlagLoading || isTelemetryQueryLoading,
    data,
    noData: data?.getContactCollectionByMagicLink === null,
    ogImage,
    ogTitle,
    ogUrl,
    formik,
    context,
    setContext,
    isMutationLoading: isCreateContactMutationLoading || isDeleteContactMutationLoading,
    hasMutationError: !!createContactMutationError || !!deleteContactMutationError,
    telemetryData: {
      postgresEventId: telemetryQueryData?.eventById?.id ?? '',
      firebaseEventId: telemetryQueryData?.eventById?.firebaseId ?? '',
      joyEventType: telemetryQueryData?.eventById?.info.eventType ?? ''
    }
  };
};
