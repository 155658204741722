import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { withWindow } from '@shared/utils/withWindow';
import { Flex, TextV2, Box, ButtonV2 } from '@withjoy/joykit';
import React from 'react';
import useMatchingPrintModal from './usePrintCrossSellModal';

interface ContentProps {
  isOpen: boolean;
  onClose: () => void;
  eventHandle: string;
}

const Content: React.FC<ContentProps> = ({ isOpen, onClose, eventHandle }) => {
  const handleOpenCards = () => {
    onClose();
    withWindow(w => {
      w.open(`/${eventHandle}/edit/cards`, '_blank');
    });
  };

  return (
    <ResponsiveDialog
      dialogSize="2xl"
      drawerOverrides={{
        Body: {
          props: {
            paddingLeft: [0, 0, 0],
            paddingRight: [0, 0, 0],
            paddingBottom: [0, 0, 0],
            paddingTop: [0, 0, 0]
          }
        },
        Content: {
          props: {
            height: 'auto',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }
        }
      }}
      dialogOverrides={{
        Header: {
          props: {
            paddingBottom: [0, 0, 0]
          }
        },
        Body: {
          props: {
            paddingLeft: [0, 0, 0],
            paddingRight: [0, 0, 0],
            paddingBottom: [0, 0, 0],
            paddingTop: [0, 0, 0]
          }
        }
      }}
      isOpen={isOpen}
      onClose={onClose}
      hideDivider
      removeBodyPadding
    >
      <Flex flexDirection={['column-reverse', null, 'column']} padding={7} paddingTop={0} paddingBottom={[7, null, 0]} gap={[5, null, 8]}>
        <Flex flexDirection="column" alignItems="center" gap={[4, null, 5]}>
          <TextV2 fontSize={['26px', null, '36px']} lineHeight={['34px', null, '45px']} textAlign="center" fontWeight={600} maxWidth="500px">
            Address Envelopes Effortlessly.
          </TextV2>
          <TextV2 fontSize={['17px', null, '24px']} lineHeight="32px" textAlign="center" maxWidth="500px">
            Use our contact collector to gather all the guest information and reach everyone.
          </TextV2>
        </Flex>
        <Box padding="38% 0 0 0" position="relative">
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            as="iframe"
            allow="autoplay; fullscreen"
            borderWidth={0}
            title="video"
            src="https://player.vimeo.com/video/1001250245?autoplay=1&autopause=0&muted=1&controls=0&badge=0&player_id=0&app_id=58479&loop=1"
          />
        </Box>
      </Flex>
      <Flex
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        backgroundColor="white"
        position="sticky"
        bottom={0}
        flex="1"
        justifyContent="center"
        paddingX={6}
        paddingY={5}
        borderTop="1px solid #EBEBEB"
      >
        <ButtonV2 onClick={handleOpenCards} width={['100%', null, 'auto']} size="lg" intent="neutral" shape="rounded">
          Browse Card Designs
        </ButtonV2>
      </Flex>
    </ResponsiveDialog>
  );
};

interface RootProps {
  eventHandle: string;
  eventId: string;
}

const Root: React.FC<RootProps> = ({ eventHandle, eventId }) => {
  const { isOpen, handleClose } = useMatchingPrintModal({ eventId });

  return <Content isOpen={isOpen} onClose={handleClose} eventHandle={eventHandle} />;
};

export default Root;
