import { useCallback, useMemo } from 'react';

import { monorepoRoutePaths, routePaths as adminRoutePaths, routePaths } from '@apps/admin/route.paths';
import { useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';
import { EventPageSlugs } from '@apps/admin/routes/Dashboard/common/enums/EventPageSlugs';
import { ProductVisibilityEnum } from '@apps/admin/routes/Dashboard/common/enums/ProductVisibilityEnum';
import { useRouterHelper, useTranslation } from '@shared/core';
import { Calendar, Design, EditPages, Faq, Guests, Home, Photos, Registry, Settings, Story, Plane, Video, Bed, CustomPage, Add } from '@withjoy/joykit/icons';

import { ProductVerticals } from '@shared/core/productVerticals/productVerticals.types';
import { ProductRow } from '../../PrimaryDashboardContent/PrimaryDashboardContent.types';
import { ProductRowSubItem } from '../../PrimaryDashboardContent/ProductRowItem';
import { PageInfo } from './useProductRows';
import { useFeatureValue } from '@shared/core/featureFlags';
import { PlanningRoutes } from '@apps/admin/routes/Planning/Planning.routes';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { EventPageType } from '@graphql/generated';
import { useAddCustomPageDialogContext } from '@shared/context/AddCustomPageDialog/AddCustomPageDialog.provider';

function getWebsiteEnablementAndVisibility(eventPages: PageInfo[], productVerticals: ProductVerticals) {
  const websiteSectionPages = eventPages.filter(({ pageSlug }) => pageSlug !== EventPageSlugs.Registry && pageSlug !== EventPageSlugs.Rsvp && pageSlug !== EventPageSlugs.Guests);
  let areAllPagesPrivate = true;
  let areAllPagesDisabled = true;

  websiteSectionPages.forEach(page => {
    if (page.visibility !== 'hidden') {
      areAllPagesDisabled = false;

      if (page.visibility !== 'passwordProtected' && page.pageSlug !== EventPageSlugs.Welcome) {
        areAllPagesPrivate = false;
      }
    }
  });

  return {
    isEnabled: !productVerticals.optOutOfWebsite,
    visibility: areAllPagesDisabled ? ProductVisibilityEnum.Hidden : areAllPagesPrivate ? ProductVisibilityEnum.Private : ProductVisibilityEnum.Public
  };
}

export const useWebsiteProductRow = (eventPages: PageInfo[], productVerticals: ProductVerticals, eventId?: string, shouldShowOnboardingSurvey?: boolean): ProductRow => {
  const isHotelBlockEnabled = useFeatureValue('hotelBlockEnabled').value === 'on';

  const [isMobile] = useResponsive({ values: { xxs: true, xs: false } });
  const routeHelpers = useRouterHelper();
  const telemetry = useDashboardTelemetry();
  const { t2 } = useTranslation('adminDashboardV2');
  const visibilityTranslations = t2('visibility');
  const { website: websiteTranslations } = t2('sections');
  const customPageEnabled = useFeatureValue('customPageEnabled').value === 'treatment';
  const { openAddCustomPageDialog, setCustomPageDialogData } = useAddCustomPageDialogContext();

  const resolveWebsiteRoute = useCallback((path: string, legacyRoute?: boolean) => {
    return path;
  }, []);

  const websiteProductRow = useMemo<ProductRow>(() => {
    const websiteStrings = websiteTranslations;
    const visibilityStrings = visibilityTranslations;
    const { isEnabled, visibility } = getWebsiteEnablementAndVisibility(eventPages, productVerticals);
    const pagesDisabled = new Set<string>();
    eventPages.forEach(({ pageSlug, visibility }) => visibility === 'hidden' && pagesDisabled.add(pageSlug));

    let editPagesSubItems: ProductRowSubItem[] = [];
    const editItemsDictionary = {
      [EventPageSlugs.Welcome]: [
        {
          title: websiteStrings.children.editPages.children.welcome.title,
          icon: Home,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.welcome), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'welcome', section: 'Website', categoryArg }),
          isDisabled: pagesDisabled.has(EventPageSlugs.Welcome),
          telemetryName: monorepoRoutePaths.welcome
        },
        {
          title: websiteStrings.children.editPages.children.video.title,
          icon: Video,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.video), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'video', section: 'Website', categoryArg }),
          isDisabled: pagesDisabled.has(EventPageSlugs.Video),
          telemetryName: monorepoRoutePaths.video
        }
      ],
      [EventPageSlugs.Story]: [
        {
          title: websiteStrings.children.editPages.children.story.title,
          icon: Story,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.story), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'story', section: 'Website', categoryArg }),
          isDisabled: pagesDisabled.has(EventPageSlugs.Story),
          telemetryName: monorepoRoutePaths.story
        }
      ],
      [EventPageSlugs.Schedule]: [
        {
          title: websiteStrings.children.editPages.children.schedule.title,
          icon: Calendar,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.schedule), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'schedule', section: 'Website', categoryArg }),
          isDisabled: pagesDisabled.has(EventPageSlugs.Schedule),
          telemetryName: monorepoRoutePaths.schedule
        }
      ],
      [EventPageSlugs.Travel]: [
        {
          title: websiteStrings.children.editPages.children.travel.title,
          icon: Plane,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.travel), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'travel', section: 'Website', categoryArg }),
          isDisabled: pagesDisabled.has(EventPageSlugs.Travel),
          telemetryName: monorepoRoutePaths.travel
        }
      ],
      [EventPageSlugs.Accommodations]: [
        {
          title: websiteStrings.children.editPages.children.accommodations.title,
          icon: Bed,
          path: routeHelpers.buildPath(EventPageSlugs.Accommodations),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'adminAccommodations', section: 'Website', categoryArg }),
          hasNewPill: true,
          pathIsInJoyWeb: true,
          isDisabled: pagesDisabled.has(EventPageSlugs.Accommodations),
          telemetryName: EventPageSlugs.Accommodations
        }
      ],
      [EventPageSlugs.Faq]: [
        {
          title: websiteStrings.children.editPages.children.guest.title,
          icon: Faq,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.faq), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'faq', section: 'Website', categoryArg }),
          isDisabled: pagesDisabled.has(EventPageSlugs.Faq),
          telemetryName: monorepoRoutePaths.faq
        }
      ],
      [EventPageSlugs.WeddingParty]: [
        {
          title: websiteStrings.children.editPages.children.weddingParty.title,
          icon: Guests,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.vip), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'vip', section: 'Website', categoryArg }),
          isDisabled: pagesDisabled.has(EventPageSlugs.WeddingParty),
          telemetryName: monorepoRoutePaths.vip
        }
      ],
      [EventPageSlugs.Registry]: [
        {
          title: websiteStrings.children.editPages.children.registry.title,
          icon: Registry,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.registryLinks), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'registrylinks', section: 'Website', categoryArg }),
          isDisabled: pagesDisabled.has(EventPageSlugs.Registry),
          telemetryName: monorepoRoutePaths.registryLinks
        }
      ],
      [EventPageSlugs.Custom]: (pageSlug: string, pageTitle?: string) => {
        return [
          {
            title: pageTitle ?? '',
            icon: CustomPage,
            path: routeHelpers.buildPath(routePaths.customPage.goToPath(pageSlug)),
            onClick: (categoryArg?: string) => telemetry.openPage({ label: `website.custompage`, section: 'Website', categoryArg, pageSlug }),
            pathIsInJoyWeb: true,
            isDisabled: pagesDisabled.has(pageSlug),
            telemetryName: pageSlug
          }
        ];
      }
    };

    eventPages.forEach(page => {
      const item = editItemsDictionary[page.pageSlug as keyof typeof editItemsDictionary];

      if (item && typeof item !== 'function' && (page.pageSlug === EventPageSlugs.Accommodations || page.type !== EventPageType.custom)) {
        editPagesSubItems = editPagesSubItems.concat(item);
      } else if (customPageEnabled && page.type === EventPageType.custom) {
        const itemCustom = editItemsDictionary[EventPageSlugs.Custom as keyof typeof editItemsDictionary];
        if (typeof itemCustom === 'function') {
          editPagesSubItems = editPagesSubItems.concat(itemCustom(page.pageSlug, page?.pageTitle));
        }
      }
    });

    return {
      title: websiteStrings.title,
      stringOn: visibilityStrings.on,
      stringOff: visibilityStrings.off,
      settingsUrl: routeHelpers.buildPath(monorepoRoutePaths.settingsMenu),
      onPageVisibilityClick: () => telemetry.openPage({ label: 'PageVisibility', section: 'Website' }),
      isEnabled,
      visibility,
      children: [
        {
          title: websiteStrings.children.design.title,
          icon: Design,
          path: resolveWebsiteRoute(routeHelpers.buildPath(adminRoutePaths.design.path)),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'websiteDesigner', section: 'Website', categoryArg }),
          pathIsInJoyWeb: true,
          telemetryName: adminRoutePaths.design.path
        },
        {
          title: websiteStrings.children.photos.title,
          icon: Photos,
          path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.photos), true),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'photos', section: 'Website', categoryArg }),
          telemetryName: monorepoRoutePaths.photos
        },
        // In Desktop, the element is positioned between Edit Pages and Photo.
        ...(!isMobile && isHotelBlockEnabled
          ? [
              {
                title: websiteStrings.children.roomBlocks.title,
                icon: Bed,
                path: routeHelpers.buildPath(PlanningRoutes.hotelblock),
                onClick: (categoryArg?: string) => telemetry.openPage({ label: 'adminRoomBlockTile', section: 'Website', categoryArg }),
                hasNewPill: true,
                pathIsInJoyWeb: true,
                telemetryName: PlanningRoutes.hotelblock
              }
            ]
          : []),
        {
          title: websiteStrings.children.editPages.title,
          icon: EditPages,
          subItems: [
            ...editPagesSubItems,
            ...(customPageEnabled
              ? [
                  {
                    title: websiteStrings.children.editPages.children.addNewPage.title,
                    icon: Add,
                    onClick: (categoryArg?: string) => {
                      telemetry.addNewPageOpen();
                      openAddCustomPageDialog();
                      setCustomPageDialogData({ pages: eventPages });
                    },
                    hasDivider: true,
                    hasNewPill: true,
                    telemetryName: 'addNewPage'
                  }
                ]
              : []),
            {
              title: websiteStrings.children.editPages.children.managePages.title,
              icon: Settings,
              path: resolveWebsiteRoute(routeHelpers.buildPath(monorepoRoutePaths.settingsMenu), true),
              onClick: (categoryArg?: string) => telemetry.openPage({ label: 'managePages', section: 'Website', categoryArg }),
              hasDivider: customPageEnabled ? false : true,
              telemetryName: monorepoRoutePaths.settingsMenu
            }
          ]
        },
        // In Mobile, the element is positioned last.
        ...(isMobile && isHotelBlockEnabled
          ? [
              {
                title: websiteStrings.children.roomBlocks.title,
                icon: Bed,
                path: routeHelpers.buildPath(PlanningRoutes.hotelblock),
                onClick: (categoryArg?: string) => telemetry.openPage({ label: 'adminRoomBlockTile', section: 'Website', categoryArg }),
                hasNewPill: true,
                telemetryName: PlanningRoutes.hotelblock
              }
            ]
          : [])
      ]
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventPages, routeHelpers, telemetry, visibilityTranslations, websiteTranslations, productVerticals, resolveWebsiteRoute]);

  return websiteProductRow;
};
