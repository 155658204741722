import React from 'react';
import { MenuItemProps, MenuItem } from './MenuItem';
import { Flex, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { NewBadge } from '@apps/admin/routes/Dashboard/components/DashboardContentV2/PrimaryDashboardContent/ProductRowItem/ProductRowItem.styles';

export type MenuSectionProps = {
  headerText?: string;
  items: MenuItemProps[];
  newPillText?: string;
  openSubmenu: (items: Omit<MenuSectionProps, 'openSubmenu' | 'onClose'>[]) => void;
  onClose: () => void;
};

export const MenuSection: React.FC<MenuSectionProps> = ({ items, headerText, openSubmenu, newPillText, onClose }) => {
  return (
    <Flex flexDirection="column" paddingX={pxToRem(12)} overflowY="auto">
      {headerText && (
        <TextV2 typographyVariant={'hed3'} paddingTop={pxToRem(24)} paddingBottom={pxToRem(12)}>
          {headerText}
        </TextV2>
      )}
      {items.map((item, i) => (
        <MenuItem expands={!!item.subItems} key={i} {...item} openSubmenu={openSubmenu} onClose={onClose} />
      ))}
      {newPillText && (
        <NewBadge color="#FFF" marginLeft={pxToRem(8)} typographyVariant={'label3'}>
          {newPillText}
        </NewBadge>
      )}
    </Flex>
  );
};
